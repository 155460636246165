.main-container {
  width: 100%;
  top: 0%;
}

.blog-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
}

.loader1 {
  border: 5px solid #eceaea;
  /* Light grey */
  border-top: 5px solid #4285f4;
  /* Primary */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1.5s linear infinite;
  /* Safari */
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overflow-elip {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-active {
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
  color: white;
  border-radius: 8px;
  font-weight: 600;
}

.qr-modal {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  /* margin-top: 2rem; */
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
}

.loader-view {
  display: flex;
  justify-content: center;
}

.new-loader {
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}


/* ================= */

/* Add Blog Css */

.add-container {
  width: 100%;
  /* max-width: 70%; */
}

.add-left-container {
  width: 100%;
  max-width: 70%;
}

.add-right-container {
  width: 100%;
  max-width: 30%;
}

.form-handler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  border: 2px dashed;
  border-color: (-border-primary);
  padding: 15px;
  margin: 1rem 0px;
}


.uploded-row {
  width: 100%;
  max-width: 80%;
  padding: 5px 0px;
  padding-left: 5px;
  border-radius: 5px;
  background-color: #e9f0ff;
  cursor: pointer;
  margin: 10px 0px;
}

.category-handler {
  overflow: auto;
  height: 20vh;
  padding: 0px 10px;
  border: 1px solid whitesmoke;
  border-radius: 5px;
  margin: 1rem 5px;
}

.category-handler::-webkit-scrollbar {
  display: none;
}


@media only screen and (max-width: 1260px) {
  .add-left-container {
    max-width: 60%
  }

  .add-right-container {
    max-width: 40%;
  }
}


@media only screen and (max-width: 1024px) {
  .blog-handler {
    flex-direction: column;
  }

  .img-blog {
    width: 100%;
  }
}


@media only screen and (max-width: 992px) {
  .add-container {
    flex-direction: column;
  }

  .add-left-container {
    max-width: 100%;
  }

  .add-right-container {
    max-width: 100%;
  }

}


/* Blog Page code start from */

.blog-btn .btn {
  border: 1px solid;
  background-color: #38bdf8;
  color: white;
  padding: 3px 20px;
  font-size: 12px;
}

@font-face {
  font-family: productLight;
  src: url("../public/font/FontsFree-Net-ProductSans-Light.ttf");
}
#para a {
  color: blue !important;
}
#para img {
 width: 100% !important;
 padding: 2% !important;
 object-fit: fill !important;
 /* height: 400px !important; */
}

.modal-footer .btn{
 background-color: #4285f4 !important;
}

.rdw-link-modal{
  height: auto !important;
}
.rdw-link-decorator-wrapper span{
  color: blue;
  }
